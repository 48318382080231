<template>
  <div class="floor-plan-details">
    <div class="container fluid">
      <div v-if="!isEmpty(floorPlan)" class="content">
        <!-- ============================ HEADER =========================== -->
        <floor-plan-detail-header
          class="card mb-1"
          :floorPlan="floorPlan"
          :projectRoute="{
            name: 'ManageProjectsDetails',
            params: {
              id: floorPlan.project.id
            }
          }"
          :unitTypeRoute="{
            name: 'ManageProjectUnitTypesDetails',
            params: {
              projectId: floorPlan.project.id,
              id: floorPlan.projectUnitType.id
            }
          }"
        >
        </floor-plan-detail-header>
        <!-- ============================= BODY ============================ -->
        <floor-plan-detail-body
          class="card mb-1"
          :floorPlan="floorPlan"
        ></floor-plan-detail-body>

        <!-- Units -->
        <collapsible v-model="isUnitShown" class="card p-1 mb-1">
          <template #title>
            <h4>Units</h4>
          </template>

          <div class="sm-p-2 col-12">
            <units
              type="agency"
              :canAdd="canAddUnit"
              :canEdit="canEditUnit"
              :canDelete="canDeleteUnit"
              :projectId="$route.params.projectId"
              :unitTypeId="$route.params.unitTypeId"
              :floorPlanId="$route.params.id"
            ></units>
          </div>
        </collapsible>

        <!-- Comments & Videos-->
        <div class="row">
          <!-- Comment -->
          <div class="col-12 md-col-8">
            <comments
              :floorPlanId="$route.params.id"
              class="md-mr-1"
            ></comments>
          </div>

          <!-- Video -->
          <div class="col-12 md-col-4">
            <videos :floorPlanId="$route.params.id"></videos>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import ProjectFloorPlanAPI from "@/modules/Project/api/projectFloorPlan";
const floorPlanAPI = new ProjectFloorPlanAPI("agency");

export default {
  components: {
    FloorPlanDetailHeader: () =>
      import("@/modules/Project/components/FloorPlan/Detail/Header"),
    FloorPlanDetailBody: () =>
      import("@/modules/Project/components/FloorPlan/Detail/Body"),
    Units: () => import("@/modules/Project/components/Unit/Units"),
    Collapsible: () => import("@/components/GlobalComponents/Collapsible"),
    Comments: () => import("@/components/ManageFloorPlanComments/Comments"),
    Videos: () => import("@/components/ManageFloorPlanVideos/Videos")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      floorPlan: {},

      // Unit
      canAddUnit: false,
      canEditUnit: false,
      canDeleteUnit: false,
      isUnitShown: true,

      isEmpty: isEmpty
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getFloorPlan();
      await this.getAccessPermission();
    },

    async getAccessPermission() {
      this.canAddUnit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "projectUnit.create"
      );
      this.canEditUnit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "projectUnit.update"
      );
      this.canDeleteUnit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "projectUnit.delete"
      );
    },
    // ============================== API RELATED ==============================
    async getFloorPlan() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await floorPlanAPI.getFloorPlan(this.$route.params.id);
        this.floorPlan = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Get Floor Plan Detail");
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
