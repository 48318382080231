import axios from "axios";
import API from "@/modules/Project/classes/API";
import { reportError } from "@/utils/error-reporting";

export default class ProjectFloorPlanAPI extends API {
    async getAllFloorPlans({ queries = {}, page = 1, perPage = 15 }) {
        try {
            let include = "projectUnits";

            let response = await axios.get(
                `${ProjectFloorPlanAPI.apiURL}/floor-plans`,
                {
                    params: {
                        ...queries,
                        include: include,
                        page: page,
                        limit: perPage
                    }
                }
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectFloorPlanAPI.panelName}] Get Proj.F.Plans`
            );
            throw error;
        }
    }
    async getFloorPlan(id) {
        let include = "projectUnits";

        try {
            let response = await axios.get(
                `${ProjectFloorPlanAPI.apiURL}/floor-plans/${id}?include=${include}`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectFloorPlanAPI.panelName}] Get Proj.F.Plan (#${id})`
            );
            throw error;
        }
    }
    async createFloorPlan(payload) {
        try {
            let response = await axios.post(
                `${ProjectFloorPlanAPI.apiURL}/floor-plans`,
                payload
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectFloorPlanAPI.panelName}] Create Proj.F.Plan`
            );
            throw error;
        }
    }
    async updateFloorPlan({ id, payload }) {
        try {
            let response = await axios.put(
                `${ProjectFloorPlanAPI.apiURL}/floor-plans/${id}`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectFloorPlanAPI.panelName}] Update Proj.F.Plan (#${id})`
            );
            throw error;
        }
    }
    async deleteFloorPlan(id) {
        try {
            const response = await axios.delete(
                `${ProjectFloorPlanAPI.apiURL}/floor-plans/${id}`
            );

            return response;
        } catch (error) {
            reportError(
                error,
                `[${ProjectFloorPlanAPI.panelName}] Delete Proj.F.Plan (#${id})`
            );
            throw error;
        }
    }
}
